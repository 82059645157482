<template>
  <lf-input
    ref="inputRef"
    :value="formattedValue || effectiveValue"
    :placeholder="placeholder"
    :name="name"
  >
    <template #suffix>
      <slot name="suffix" />
    </template>
  </lf-input>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import type { CurrencyInputOptions } from "vue-currency-input";
import useCurrencyInput from "vue-currency-input";
import debounce from "lodash/debounce";

const {
  modelValue,
  options = {},
  placeholder,
  name,
  defaultValue
} = defineProps<{
  modelValue?: number | string;
  options?: CurrencyInputOptions;
  placeholder?: string;
  name: string;
  defaultValue?: number;
}>();

const DEFAULT_OPTIONS = {
  currency: "USD",
  locale: "en",
  distractionFree: true,
  useGrouping: true,
  precision: 2,
  valueRange: { min: 0, max: 999999999 }
} as const;

const effectiveOptions = computed(() => {
  return {
    ...DEFAULT_OPTIONS,
    ...options
  };
});

const { formattedValue, inputRef, setOptions, setValue } = useCurrencyInput(
  effectiveOptions.value
);

const effectiveValue = computed(() => modelValue ?? defaultValue);

const debouncedSetValue = debounce((value) => {
  setValue(value);
}, 100);

watch(
  () => modelValue,
  () => {
    const value = parseFloat(`${effectiveValue.value}`);
    debouncedSetValue(value);
  }
);

watch(
  () => options,
  () => {
    setOptions(effectiveOptions.value);
  },
  { flush: "post" }
);
</script>
